<template>
  <div>
    <!-- 发起收集任务 -->
    <!-- <el-row style="margin-bottom: 20px"></el-row> -->
    <!-- 条件 -->
    <el-row>
      <el-button
        type="primary"
        style="height: 40px; margin-right: 20px"
        @click="dialogVisibleBtn('add')"
        >发布新任务</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-form :inline="true" ref="conditionForm" :model="conditionForm">
        <el-form-item label="名称/编号" class="formItemBoxStyle">
          <el-input
            v-model="conditionForm.crsExpPlanNameOrCode"
            @change="changeConditionForm"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态" class="formItemBoxStyle">
          <el-select
            v-model="conditionForm.executiveStatus"
            @change="changeConditionForm"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未开始" :value="1"></el-option>
            <el-option label="进行中" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" class="formItemBoxStyle">
          <el-date-picker
            v-model="conditionTimeList"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeConditionTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @change="inquireBtn">查询</el-button>
          <el-button @click="resetBtn">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <!-- 任务状态一栏 -->
    <!-- <div style="display: flex">
      <div
        class="taskStatusItem"
        v-for="(item, i) in taskStatusList"
        :key="item.name"
        :class="item.isSelect ? 'selected' : ''"
        @click="handleSelectTaskStatus(item, i)"
      >
        <span>{{ item.name }}（{{ item.num }}）</span>
      </div>
    </div> -->
    <!-- 表格 -->
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        ref="tableData"
        :data="tableData"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="crsExpPlanCode"
          label="任务编号"
        ></el-table-column>
        <el-table-column label="任务类型" prop="taskType" width="180">
          <span>实验教学计划</span>
        </el-table-column>
        <el-table-column
          prop="crsExpPlanName"
          label="教学计划名称"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-link
              type="primary"
              @click="dialogVisibleBtn('watch', scope.row)"
              >{{ scope.row.crsExpPlanName }}</el-link
            >
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间">
          <template slot-scope="scope">
            {{ scope.row.startTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="结束时间">
          <template slot-scope="scope"
            >{{ scope.row.endTime | dayFilters }}
          </template>
        </el-table-column>
        <el-table-column label="执行学校" show-overflow-tooltip>
          <template slot-scope="scope">
            <span
              v-for="item in scope.row.executiveSchoolList"
              :key="item.sysOrgSchoolId"
              >{{ item.name }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="状态" width="70">
          <template slot-scope="scope">
            {{ scope.row.executiveStatus | executiveStatusFilters}}
          </template>
        </el-table-column>
        <el-table-column label="操作" show-overflow-tooltip width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              style="color: #67c23a"
              @click="completionBtn(scope.row)"
              >完成情况</el-button
            >
            <el-button
              size="mini"
              type="text"
              @click="dialogVisibleBtn('update', scope.row)"
              >编辑任务</el-button
            >
            <el-button
              size="mini"
              type="text"
              style="color: red"
              @click="deleteExpPlanBtn(scope.row.crsExpPlanId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>
    <!-- 添加编辑查看弹窗 -->
    <el-dialog
      :title="dialogVisibleTit"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form
        :model="dialogVisibleData"
        :rules="dialogVisibleRules"
        ref="dialogVisibleData"
        label-width="80px"
        hide-required-asterisk
        class="demo-ruleForm"
      >
        <el-form-item label="任务编号" prop="crsExpPlanCode">
          <el-input
            v-model="dialogVisibleData.crsExpPlanCode"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="任务名称" prop="crsExpPlanName">
          <el-input
            v-model="dialogVisibleData.crsExpPlanName"
            :disabled="watchDialogVisible"
          ></el-input>
        </el-form-item>
        <el-form-item label="任务时间" prop="dialogVisibleTimeList">
          <el-date-picker
            v-model="dialogVisibleTimeList"
            value-format="timestamp"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            style="width: 100%"
            @change="changeDialogVisibleTime"
            :disabled="watchDialogVisible"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="学年学期" prop="sysSchoolTermId">
          <!-- <el-select
            style="width: 100%"
            v-model="dialogVisibleData.sysSchoolTermId"
            placeholder="学年学期"
            :disabled="watchDialogVisible"
          >
            <el-option
              v-for="item in $store.state.public_data.sysAcademicYearDicList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select> -->
          <el-cascader
            style="width: 100%"
            v-model="dialogVisibleData.sysSchoolTermId"
            :options="$store.state.public_data.sysAcademicTermYearDicList"
            :props="{
              children: 'children',
            }"
            clearable
            :disabled="watchDialogVisible"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="所属年级" prop="sysSchoolClassGradeIdList">
          <el-cascader
            style="width: 100%"
            v-model="dialogVisibleData.sysSchoolClassGradeIdList"
            :options="$store.state.public_data.sysSemesterTermGradeDicList"
            :props="{
              multiple: true,
              children: 'children',
            }"
            clearable
            :disabled="watchDialogVisible"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="执行学校" prop="executiveSchoolList">
          <div class="chooseSchoolBox">
            <span
              v-for="item in this.dialogVisibleData.executiveSchoolList"
              :key="item.sysOrgSchoolId"
              >{{ item.name }}、</span
            >
            <el-button
              type="text"
              class="chooseBtn"
              @click="chooseBtn"
              v-if="!watchDialogVisible"
              >选择</el-button
            >
          </div>
        </el-form-item>
        <el-form-item label="详情备注">
          <el-input
            type="textarea"
            v-model="dialogVisibleData.mark"
            :disabled="watchDialogVisible"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="uploadBtn('dialogVisibleData')"
          v-if="!watchDialogVisible"
          >保存发布</el-button
        >
      </span>
      <!-- 执行学校弹框 -->
      <el-dialog
        width="700px"
        title="执行学校"
        :visible.sync="dialogVisibleSchool"
        append-to-body
      >
        <!-- 选中框 -->
        <div style="font-size: 18px">
          已选中
          <span
            @click="deleteAllBtn"
            style="
              color: red;
              float: right;
              font-size: 15px;
              cursor: pointer;
              display: block;
            "
            >清空所有</span
          >
        </div>
        <div class="dialogVisibleSchoolTop">
          <span
            v-for="(item, i) in dialogVisibleSchoolChooseData"
            class="chooseLi"
            :key="item.sysOrgSchoolId"
            >{{ item.name }}<i @click="deleteIBtn(i)" class="el-icon-close"></i
          ></span>
        </div>
        <div style="font-size: 18px">学校列表</div>
        <!-- 条件框 -->
        <el-row>
          <el-form
            :inline="true"
            ref="dialogVisibleSchoolConditionForm"
            :model="dialogVisibleSchoolConditionForm"
          >
            <el-form-item style="margin-bottom: 10px">
              <el-select
                v-model="dialogVisibleSchoolConditionForm.sysOrgAreaId"
                @change="dialogChangeConditionForm"
                size="mini"
                placeholder="选择区县"
              >
                <el-option label="全部" value></el-option>
                <el-option
                  v-for="item in dialogVisibleSysOrgAreaIdList"
                  :key="item.sysOrgAreaId"
                  :label="item.name"
                  :value="item.sysOrgAreaId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px">
              <el-input
                size="mini"
                v-model="dialogVisibleSchoolConditionForm.crsExpPlanName"
                @change="dialogChangeConditionForm"
              ></el-input>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px">
              <el-button size="mini" type="primary" @change="dialogInquireBtn"
                >查询</el-button
              >
              <el-button size="mini" @click="dialogResetBtn">重置</el-button>
            </el-form-item>
            <el-form-item style="margin-bottom: 10px; float: right">
              <el-button
                size="mini"
                type="primary"
                @click="addSchoolChooseData(false)"
                >批量添加</el-button
              >
            </el-form-item>
          </el-form>
        </el-row>
        <!-- 表格 -->
        <el-row>
          <el-table
            v-loading="dialogLoading"
            border
            ref="dialogVisibleSchoolTableData"
            :data="dialogVisibleSchoolTableData"
            tooltip-effect="dark"
            :header-cell-style="{
              'text-align': 'center',
              backgroundColor: '#dfe6ec',
            }"
            :cell-style="{ 'text-align': 'center' }"
            style="width: 100%"
            height="340px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              :selectable="selectable"
              width="55"
            >
            </el-table-column>
            <el-table-column
              prop="sysOrgAreaName"
              label="区县"
            ></el-table-column>
            <el-table-column prop="code" label="代码"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column label="操作" show-overflow-tooltip width="70">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  @click="addSchoolChooseData(scope.row)"
                  :disabled="!selectable(scope.row)"
                  >添加</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-pagination
            @size-change="dialogHandleSizeChange"
            @current-change="dialogHandleCurrentChange"
            :current-page="dialogPage.pageIndex"
            :page-sizes="[10, 20, 50, 100, 150, 200]"
            :page-size="dialogPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="dialogPage.totalSize"
          ></el-pagination>
        </el-row>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisibleSchool = false"
            >取 消</el-button
          >
          <el-button size="small" @click="dialogSaveBtn" type="primary"
            >保存</el-button
          >
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import {
  getExpPlanList,
  addExpPlan,
  updateExpPlan,
  deleteExpPlan,
  getExecutiveSchoolCondition,
  getExecutiveSchool,
} from '@/api/courseStart/lessonPlansManage.js'
export default {
  name: 'lessonPlansManage',
  data() {
    const requiredRules = {
      required: true,
      message: '必填项',
      trigger: 'blur',
    }
    return {
      conditionTimeList: [],
      conditionForm: {
        crsExpPlanNameOrCode: '',
        executiveStatus: '',
        startTime: '',
        endTime: '',
      },
      loading: false,
      tableData: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 3,
      },
      // 编辑/添加/任务详情 弹框 内容
      dialogVisible: false,
      dialogVisibleType: 'add',
      dialogVisibleTit: '新建任务',
      dialogVisibleData: {},
      dialogVisibleCrsExpPlanId: '',
      dialogVisibleRules: {
        crsExpPlanName: [requiredRules],
        executiveSchoolList: [requiredRules],
        sysSchoolTermId: [requiredRules],
        sysSchoolClassGradeIdList: [requiredRules],
        // dialogVisibleTimeList: [requiredRules],
      },
      dialogVisibleTimeList: [],
      watchDialogVisible: false,
      // 执行学校 弹框 内容
      dialogVisibleSchool: false,
      dialogVisibleSchoolChooseData: [],
      dialogVisibleSchoolTableData: [],
      dialogVisibleSchoolConditionForm: {
        sysOrgAreaId: 0,
        sysOrgSchoolGradeIdList: [],
        sysOrgSchoolNameOrCode: '',
      },
      dialogVisibleSysOrgAreaIdList: [],
      selectionList: [],
      dialogLoading: false,
      //分页
      dialogPage: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      // 完成情况 弹框 内容

      taskStatusList: [
        {
          name: '全部',
          num: 0,
          isSelect: true,
        },
        {
          name: '未开始',
          num: 0,
          isSelect: false,
        },
        {
          name: '进行中',
          num: 0,
          isSelect: false,
        },
        {
          name: '已结束',
          num: 0,
          isSelect: false,
        },
      ],
    }
  },
  async created() {
    const { dataArr } = this.$store.state.public_data
    await Promise.all(dataArr.map((e) => this.$store.dispatch('loadData', e)))
  },
  mounted() {
    //分页查询实验教学计划
    this.getExpPlanListFunc({})
  },
  methods: {
    // 分页查询实验教学计划
    getExpPlanListFunc(condition) {
      getExpPlanList(this.page.pageIndex, this.page.pageSize, condition)
        .then((res) => {
          if (res.success) {
            console.log('请求实验教学计划列表成功：', res.data)
            this.tableData = res.data
            this.page.totalSize = res.total
          } else if (res.code != 500) {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {})
    },
    //currentPage 改变时会触发
    handleCurrentChange(val) {
      this.page.pageIndex = val
      this.changeConditionForm()
    },
    //pageSize 改变时会触发
    handleSizeChange(val) {
      this.page.pageIndex = 1
      this.page.pageSize = val
      this.changeConditionForm()
    },
    // 修改时间触发
    changeConditionTime() {
      if (!!this.conditionTimeList) {
        this.conditionForm.startTime = this.conditionTimeList[0]
        this.conditionForm.endTime = this.conditionTimeList[1]
      } else {
        this.conditionForm.startTime = ''
        this.conditionForm.endTime = ''
      }
      this.changeConditionForm()
    },
    // 条件查询
    changeConditionForm() {
      let conditionObj = JSON.parse(JSON.stringify(this.conditionForm))
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      this.getExpPlanListFunc(conditionObj)
    },
    // 查询
    inquireBtn() {
      this.page.pageIndex = 1
      this.changeConditionForm()
    },
    // 重置
    resetBtn() {
      // for (let key in this.conditionForm) {
      //   this.conditionForm[key] = ''
      // }
      this.conditionForm = {
        crsExpPlanNameOrCode: '',
        executiveStatus: '',
        startTime: '',
        endTime: '',
      }
      this.conditionTimeList = []
      this.page.pageIndex = 1
      this.getExpPlanListFunc({})
    },
    // 删除
    deleteExpPlanBtn(id) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          deleteExpPlan(id)
            .then((res) => {
              if (res.success) {
                this.$message.success('删除成功')
                this.resetBtn()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {})
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    // 新增/编辑 弹框
    dialogVisibleBtn(key, val = null) {
      this.watchDialogVisible = false
      switch (key) {
        case 'add':
          this.dialogVisibleTit = '新建任务'
          this.dialogVisibleData = {
            crsExpPlanCode: Math.random().toString(36).slice(2).substr(-6),
            crsExpPlanName: '',
            endTime: 0,
            executiveSchoolList: [],
            mark: '',
            sysSchoolClassGradeIdList: [],
            startTime: 0,
            sysSchoolTermId: [],
          }
          this.dialogVisibleTimeList = []
          break
        case 'watch':
          this.dialogVisibleTit = '任务详情'
          this.watchDialogVisible = true
          break
        case 'update':
          this.dialogVisibleTit = '编辑任务'
          this.dialogVisibleCrsExpPlanId = val.crsExpPlanId
          break
      }
      if (!!val) {
        let {
          crsExpPlanName,
          endTime,
          executiveSchoolList,
          mark,
          sysSchoolClassGradeList,
          startTime,
          sysSchoolTerm,
          crsExpPlanCode,
        } = val
        let List = sysSchoolClassGradeList.map((e) => [
          this.$store.state.public_data.sysSemesterTermGradeDicList.find(
            (item) =>
              item.children.some((c) => c.value === e.sysSchoolClassGradeId)
          )?.value,
          e.sysSchoolClassGradeId,
        ])
        let sysSchoolTermId = [
          this.$store.state.public_data.sysAcademicTermYearDicList.find(
            (item) =>
              item.children.some(
                (c) => c.value === sysSchoolTerm.sysSchoolTermId
              )
          )?.value,
          sysSchoolTerm.sysSchoolTermId,
        ]
        this.dialogVisibleData = {
          crsExpPlanCode,
          crsExpPlanName,
          endTime,
          executiveSchoolList,
          mark,
          sysSchoolClassGradeIdList: List,
          startTime,
          sysSchoolTermId,
        }
        this.dialogVisibleTimeList =
          startTime !== null && endTime !== null ? [startTime, endTime] : []
      }
      this.dialogVisibleType = key
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.dialogVisibleData.clearValidate()
      })
    },
    // 修改弹框时间触发
    changeDialogVisibleTime() {
      if (!!this.dialogVisibleTimeList) {
        this.dialogVisibleData.startTime = this.dialogVisibleTimeList[0]
        this.dialogVisibleData.endTime = this.dialogVisibleTimeList[1]
      } else {
        this.dialogVisibleData.startTime = ''
        this.dialogVisibleData.endTime = ''
      }
    },
    // 保存发布
    uploadBtn(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let sysSchoolClassGradeIdList =
            this.dialogVisibleData.sysSchoolClassGradeIdList.map((e) => e[1])
          let sysSchoolTermId = this.dialogVisibleData.sysSchoolTermId[1]
          let executiveSchoolIdList =
            this.dialogVisibleData.executiveSchoolList.map(
              (e) => e.sysOrgSchoolId
            )
          // 新增、编辑
          const apiMap = {
            add: addExpPlan,
            update: updateExpPlan,
          }
          let param = {
            crsExpPlanCode: this.dialogVisibleData.crsExpPlanCode,
            crsExpPlanName: this.dialogVisibleData.crsExpPlanName,
            endTime: this.dialogVisibleData.endTime,
            executiveSchoolIdList: executiveSchoolIdList,
            mark: this.dialogVisibleData.mark,
            sysSchoolClassGradeIdList: sysSchoolClassGradeIdList,
            startTime: this.dialogVisibleData.startTime,
            sysSchoolTermId: sysSchoolTermId,
          }
          // console.log('保存发布参数', param)
          apiMap[this.dialogVisibleType](param, this.dialogVisibleCrsExpPlanId)
            .then((res) => {
              if (res.success) {
                this.$message.success('保存成功')
                this.dialogVisible = false
                this.resetBtn()
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((err) => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 执行学校弹框
    async chooseBtn() {
      if (
        this.dialogVisibleData.sysSchoolClassGradeIdList == null ||
        !this.dialogVisibleData.sysSchoolClassGradeIdList.length
      ) {
        return this.$message.info('请先选择年级')
      }
      let sysOrgSchoolGradeIdList =
        this.dialogVisibleData.sysSchoolClassGradeIdList.map((e) => e[1])
      this.selectionList = []
      this.dialogVisibleSchoolConditionForm = {
        sysOrgAreaId: '',
        sysOrgSchoolNameOrCode: '',
        sysOrgSchoolGradeIdList,
      }
      this.dialogVisibleSchoolChooseData = [
        ...this.dialogVisibleData.executiveSchoolList,
      ]
      // 获取查询执行学校条件
      await this.getExecutiveSchoolConditionFunc({ sysOrgSchoolGradeIdList })
      // 查询执行学校
      await this.getExecutiveSchoolFunc({ sysOrgSchoolGradeIdList })

      this.dialogVisibleSchool = true
    },
    // 执行学校查询条件处理
    dialogChangeConditionForm() {
      let conditionObj = JSON.parse(
        JSON.stringify(this.dialogVisibleSchoolConditionForm)
      )
      // 条件修改
      for (let key in conditionObj) {
        if (conditionObj[key] === '' || conditionObj[key] === undefined) {
          delete conditionObj[key]
        }
      }
      // this.getExecutiveSchoolConditionFunc(conditionObj)
      this.getExecutiveSchoolFunc(conditionObj)
    },
    // 分页
    //currentPage 改变时会触发
    dialogHandleCurrentChange(val) {
      this.dialogPage.pageIndex = val
      this.dialogChangeConditionForm()
    },
    //pageSize 改变时会触发
    dialogHandleSizeChange(val) {
      this.dialogPage.pageIndex = 1
      this.dialogPage.pageSize = val
      this.dialogChangeConditionForm()
    },
    // 查询
    dialogInquireBtn() {
      this.dialogPage.pageIndex = 1
      this.dialogChangeConditionForm()
    },
    // 重置
    dialogResetBtn() {
      this.dialogVisibleSchoolConditionForm.sysOrgAreaId = ''
      this.dialogVisibleSchoolConditionForm.sysOrgSchoolNameOrCode = ''
      this.dialogPage.pageIndex = 1
      this.dialogChangeConditionForm()
    },
    // 禁用勾选
    selectable(row, index) {
      // return !~this.dialogVisibleSchoolChooseData.indexOf(row)
      return !this.dialogVisibleSchoolChooseData.some(
        (e) => e.sysOrgSchoolId === row.sysOrgSchoolId
      )
    },
    // 选中勾选
    handleSelectionChange(val) {
      this.selectionList = val
      console.log('selectionList', this.selectionList)
    },
    // 添加 单/多
    addSchoolChooseData(row = null) {
      console.log(row)
      if (!!row) {
        console.log('row=========》', row)
        this.dialogVisibleSchoolChooseData.push(row)
      } else {
        console.log('selectionList=========》', this.selectionList)
        if (this.selectionList.length) {
          let combinedArray = [
            ...this.dialogVisibleSchoolChooseData,
            ...this.selectionList,
          ]
          let map = new Map()
          combinedArray.forEach((item) => {
            if (!map.has(item.sysOrgSchoolId)) {
              map.set(item.sysOrgSchoolId, item)
            }
          })
          this.dialogVisibleSchoolChooseData = Array.from(map.values())
        }
      }
    },
    // 单个删除
    deleteIBtn(i) {
      console.log('i', i)
      this.dialogVisibleSchoolChooseData.splice(i, 1)
      // 考虑是否刷新table
    },
    // 清除
    deleteAllBtn() {
      this.dialogVisibleSchoolChooseData = []
      // 考虑是否刷新table
    },
    // 保存
    dialogSaveBtn() {
      this.dialogVisibleData.executiveSchoolList =
        this.dialogVisibleSchoolChooseData
      this.dialogVisibleSchool = false
    },
    // 获取查询执行学校条件
    async getExecutiveSchoolConditionFunc(data) {
      await getExecutiveSchoolCondition(data)
        .then((res) => {
          if (res.success) {
            this.dialogVisibleSysOrgAreaIdList = res.data.sysOrgAreaList
          }
        })
        .catch((err) => {})
    },
    // 条件分页查询执行学校
    async getExecutiveSchoolFunc(data) {
      await getExecutiveSchool(
        this.dialogPage.pageIndex,
        this.dialogPage.pageSize,
        data
      )
        .then((res) => {
          if (res.success) {
            console.log('查询执行学校成功：', res.data)
            this.dialogVisibleSchoolTableData = res.data
            this.dialogPage.totalSize = res.total
          }
        })
        .catch((err) => {})
    },
    // 完成情况
    completionBtn(row) {
      this.$router.push({
        path: '/courseStart/lessonPlansManageCompletion',
        query: {
          crsExpPlanId: row.crsExpPlanId,
        },
      })
    },
    // 选择任务状态
    handleSelectTaskStatus(item, i) {
      this.taskStatusList.forEach((item) => {
        item.isSelect = false
      })
      this.taskStatusList[i].isSelect = true
    },
  },
  watch: {},
  filters: {
    executiveStatusFilters(key) {
      switch (key) {
        case 1:
          return `未开始`
        case 2:
          return `进行中`
        case 3:
          return `已结束`
        default:
          return `未知${key}`
      }
    },
    dayFilters(val) {
      // return val ? dayjs(val).format('YYYY-MM-DD HH:mm') : ''
      return val ? dayjs(val).format('YYYY/MM/DD') : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.chooseSchoolBox {
  position: relative;

  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  min-height: 70px;
  padding: 10px 34px 10px 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  .chooseBtn {
    position: absolute;
    right: 2px;
    top: calc(50% - 20px);
  }
}
// 选中框
.dialogVisibleSchoolTop {
  width: 100%;
  height: 160px;
  border: 1px solid #ccc;
  margin: 10px 0;
  border-radius: 4px;
  box-sizing: border-box;
  // padding: 10px 10px 0;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .chooseLi {
    width: auto;
    border: 1px solid #ccc;
    padding-left: 10px;
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 5px;
    i {
      padding: 0 10px;
      border-left: 1px solid #ccc;
      height: 100%;
      width: 35px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
}
.taskStatusItem {
  width: 122px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ccc;
  span {
    font-size: 14px;
  }
  &.selected {
    border-color: rgb(84, 173, 255);
    color: rgb(84, 173, 255);
  }
}
.taskStatusItem:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.taskStatusItem:nth-child(4) {
  border-radius: 0 5px 5px 0;
}
</style>
